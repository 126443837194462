<template>
    <div class="mt-3 px-xl-5">
        <b-row>
            <h6 class="page-title pb-2 mt-3 text-uppercase">
                {{ $t("competent-authorities.title") }}
            </h6>
        </b-row>
        <b-row class="justify-content-end mb-3">
            <b-col v-if="allowCreateCA" class="text-end">
                <b-button class="main-btn" @click="goToAddCompetentAuthority">
                    <b-icon-building scale="1.3"></b-icon-building>
                    <b-icon-plus scale="1.2"></b-icon-plus>
                </b-button>
            </b-col>
            <b-col xl="4" md="6" cols="6">
                <b-input-group class="mt-1">
                    <b-form-input
                        :placeholder="$t('tables.search')"
                        class="rounded-0"
                        size="sm"
                        v-model="search"
                        @keydown.enter="filterTable"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button
                            class="main-btn"
                            size="sm"
                            @click="filterTable"
                        >
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table
                        stacked="md"
                        small
                        hover
                        select-mode="single"
                        selectable
                        show-empty
                        :empty-text="$t('competent-authorities.empty-table')"
                        :filter="filter"
                        :busy.sync="isBusy"
                        :fields="fields"
                        :items="retrieveData"
                        :current-page="currentPage"
                        :per-page="take"
                        @filtered="onFiltered"
                        @row-clicked="rowClicked"
                    >
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-5">
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="take"
                    size="sm"
                    class="my-0 justify-content-end"
                >
                    <template #first-text>
                        <span
                            ><b-icon-chevron-double-left
                                scale="1.3"
                            ></b-icon-chevron-double-left
                        ></span>
                    </template>
                    <template #prev-text>
                        <span
                            ><b-icon-chevron-left
                                scale="1.3"
                            ></b-icon-chevron-left
                        ></span>
                    </template>
                    <template #next-text>
                        <span
                            ><b-icon-chevron-right
                                scale="1.3"
                            ></b-icon-chevron-right
                        ></span>
                    </template>
                    <template #last-text>
                        <span
                            ><b-icon-chevron-double-right
                                scale="1.3"
                            ></b-icon-chevron-double-right
                        ></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import { mapGetters } from "vuex";

export default {
    name: "competent-authorities",
    data() {
        return {
            fields: [
                { key: "name", label: this.$t("competent-authorities.name") },
                {
                    key: "creationDate",
                    label: this.$t("general.creation-date"),
                },
                {
                    key: "invitationDate",
                    label: "Fecha de invitación",
                },
            ],
            search: "",
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
        };
    },
    computed: {
        ...mapGetters("application", ["allowSuperintendenceCreateCA"]),
        allowCreateCA() {
            return this.allowSuperintendenceCreateCA;
        },
    },
    methods: {
        goToAddCompetentAuthority() {
            this.$router.push({
                name: "add-competent-authority",
            });
        },
        rowClicked(item) {
            this.$router.push({
                name: "competent-authority",
                params: { id: item.id },
            });
        },
        filterTable() {
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                };
                let response =
                    await RepositoryFactory.competentAuthority.getPaged(
                        payload
                    );
                this.rows = response.data.total;
                let items = response.data.items;
                for (let i = 0; i < items.length; i++) {
                    const competentAuthority = items[i];
                    competentAuthority.creationDate = this.parseAndConvertDate(
                        competentAuthority.creationDate
                    );
                    var arrdate = competentAuthority.creationDate.split(" ");
                    competentAuthority.invitationDate =arrdate[0];
                }
                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
    },
};
</script>